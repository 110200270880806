<template>
    <div class="Review">
        <!-- 表单弹窗 -->
        <div class="from">
            <el-dialog title="审核订单" :visible.sync="zhanghu" @close="closeDialog()" width="1000px">
                <div class="fen">
                    <div class="zuo">
                        <div class="neirong" v-for='(item,index) in order_detail' :key="item.index" :id="index">
                            <!--  -->
                            <div class="title tits">
                                <div>
                                    <i class=""></i>
                                </div>
                                <div>
                                    <span>{{item.agent_account.advertiser.advertiser_full_name}}</span>
                                </div>
                                <div class="danzi">
                                    <span>{{item.agent_account.advertiser.advertiser_account_id}}</span>
                                </div>
                                <el-button type="primary" class="copy" @click="doCopy(item)" style="margin-left:auto !important;">一键复制</el-button>
                            </div>
                            <div class="lie">
                                <div>充值账户名称</div>
                                <span>{{item.agent_account.agent_account_name}}</span>
                            </div>
                            <div class="lie">
                                <div>充值账户ID</div>
                                <span>{{item.agent_account.agent_account}}</span>
                            </div>
                            <div class="lie">
                                <div>充值运营类型/返点</div>
                                <span v-if="item.agent_account.is_operating_type==1">自运营</span>
                                <span v-if="item.agent_account.is_operating_type==2">代运营</span>
                                <span v-if="item.agent_account.is_operating_type==3">渠道</span>
                                <span v-if="item.agent_account.is_operating_type==4">W渠道</span>
                                <span v-if="item.agent_account.is_operating_type==5">cid</span>
                            </div>
                            <div class="lie">
                                <div>需充值广告币</div>
                                <span> {{ThousandSeparator(item.user_record_price)}} </span>
                                <!-- <p class="p">{{smallToBig(item.user_record_price)}}</p> -->
                            </div>
                            <div class="lie">
                                <div>实际到账广告币</div>
                                <span class="lies">
                                    <el-input v-model="item.actual_price" :disabled="zhuangtai == 2" placeholder="请输入金额" @input="blur(item)" oninput="value=value.replace(/[^\d\.]/g,'')"></el-input>
                                    <!-- <el-input v-model="item.actual_price" " @input="blur(item)" ></el-input> -->
                                </span>
                                <p class="p">{{smallToBig(item.actual_price)}}</p>
                            </div>
                        </div>
                    </div>
                    <!--  -->
                    <div class="you">
                        <div class="block" @click="onIMG()" style="position: relative;">
                            <el-image :src="src">
                                <div slot="placeholder" class="image-slot">
                                    加载中<span class="dot">...</span>
                                </div>
                            </el-image>
                            <!-- <div style="color:#f00;font-size:20px;position: absolute; bottom:30px;right:60px">请先查看图片</div> -->
                        </div>
                    </div>

                </div>
                <!--  -->
                <div slot="footer" class="dialog-footer bottom">
                    <div class="status">
                        <span>订单状态:</span>
                        <el-select v-model="order_status" placeholder="请选择" clearable>
                            <el-option label="未处理" value="3" size="120px"> </el-option>
                            <el-option label="处理中" value="1" size="120px"> </el-option>
                            <el-option label="处理完成" value="2" size="120px"> </el-option>
                        </el-select>
                    </div>
                    <el-button type="primary" @click="tijiao" class="tijiao primary button tijiao">提 交</el-button>
                </div>

            </el-dialog>
        </div>
    </div>
</template>

<script>
export default {
    props: ["msg", "id",],
    data () {
        return {
            data: '',
            zhanghu: false, //操控弹窗展示
            activeName: "", //显示组件
            src: "",
            order_status: "", //订单状态
            order_detail: "", //订单信息
            message: "", //复制
            code: "", //随机数
            actual_price: "",
            zhuangtai: "",
            // operationTrue:false,//审核订单点击出现按钮
        };
    },
    methods: {},

    mounted () {

    },

    methods: {
        //打开弹窗
        onzhanghu (data) {
            this.data = data
            this.src = this.data.upload_img;
            this.order_detail = this.data.order_detail;
            this.zhuangtai = this.data.order_status;
            this.order_status = this.data.order_status;
            // console.log(this.data,111)
            this.createCode();
            this.zhanghu = true
        },
        // onOrderDetail(){
        //     this.data.order_detail.forEach((v,k) => {
        //         v.actual_price = this.ThousandSeparator(v.actual_price)
        //     });
        //     this.order_detail = this.data.order_detail;
        // },
        //
        blur (item) {
            this.actual_price = item.actual_price;
        },
        //随机数
        createCode () {
            var code = "";
            var codeLength = 4;
            var random = new Array(0, 1, 2, 3, 4, 5, 6, 7, 8, 9);
            for (var i = 0; i < codeLength; i++) {
                var index = Math.floor(Math.random() * 9);
                code += random[index];
            }
            this.code = code;
        },
        // 数据处理
        dataProcessing (val) {
            var code = this.code;
            var Arr = [
                "99积分兑换1条配音，54个配音师可选。",
                "充值享准时宝，充慢赔50积分。",
                "积分可兑换配音、视频素材。",
                "每充值1万送50积分。",
                "充值速度快，到帐微信推送。",
            ];
            var n = Math.floor(Math.random() * Arr.length + 1) - 1;
            // console.log(val.agent_account.advertiser.advertiser_account_id);
            if (val.agent_account.advertiser.advertiser_account_id == null) {
                // console.log(val.agent_account.advertiser.advertiser_full_name.search('快手'),1111111)
                this.message +=
                    "时间: " + this.dateFtt("yyyyMMddhhmm", new Date()) + "\n" +
                    val.agent_account.agent_account_name +
                    "\n" +
                    "(" +
                    ((val.agent_account.advertiser.advertiser_full_name.search('快手') != -1) ? '快手账户:' : '账户ID:') +
                    val.agent_account.agent_account +
                    ")" +
                    "\n" +
                    "到账广告币" +
                    this.ThousandSeparator(val.actual_price) +
                    "\n" +
                    "到账广告币：" +
                    this.smallToBig(val.actual_price);
            } else {
                this.message +=
                    "时间: " + this.dateFtt("yyyyMMddhhmm", new Date()) + "\n" +
                    "千川账户名称: " +
                    "\n" +
                    val.agent_account.advertiser.advertiser_full_name +
                    "\n" +
                    "千川账户: " +
                    "\n" +
                    val.agent_account.advertiser.advertiser_account_id +
                    "\n" +
                    "\n" +
                    "转" +
                    "\n" +
                    val.agent_account.agent_account_name +
                    "\n" +
                    "(" +
                    (!val.agent_account.advertiser.advertiser_full_name.search('快手') ? '快手:' : '账户ID:') +
                    // "千川账户ID:" +
                    val.agent_account.agent_account +
                    ")" +
                    "\n" +
                    "到账广告币" +
                    this.ThousandSeparator(val.actual_price) +
                    "\n" +
                    "到账广告币：" +
                    this.smallToBig(val.actual_price);
            }
        },
        //一键复制
        doCopy: function (val) {
            this.message = "";
            var that = this;
            this.dataProcessing(val);

            this.$copyText(this.message).then(
                function (e) {
                    that.$message.success("复制成功");
                },
                function (e) {
                    that.$message.error("复制失败");
                }
            );
        },
        quxiao () {
            this.$emit("shenhe");
            this.zhanghu = false;
        },
        tijiao () {
            var that = this;
            var param = {
                order_msg: {
                    order_id: this.id, //订单id
                    order_status: this.order_status, //订单状态
                },
                order_detail_msg: [],
            };
            this.order_detail.forEach(function (item, index) {
                var shuju = {
                    actual_price: item.actual_price,
                    order_detail_id: item.id,
                };
                param.order_detail_msg.push(shuju);
            });
            var pan = param.order_detail_msg[0].actual_price;
            if (pan == "" || pan == 0.0 || pan == 0) {
                that.$message.warning("请填写实际到账广告币");
                return;
            } else {
                this.$service.post(
                    this.$api.updateOrderStatus,
                    param,
                    (res) => {
                        if (res.code == "200") {
                            this.$emit("shenhe");
                            this.zhanghu = false;
                        }
                    }
                );
            }
        },
        // 关闭按钮
        closeDialog () {
            // this.$emit("shenhe");
            this.data = '',
                this.activeName = "", //显示组件
                this.src = "",
                this.order_status = "", //订单状态
                this.order_detail = "", //订单信息
                this.message = "", //复制
                this.code = "", //随机数
                this.actual_price = "",
                this.zhuangtai = "",
                this.zhanghu = false;
        },
        onIMG () {
            // this.operationTrue = true
            window.open(
                "https://user.erlangcha.com/advances/Advimage?imageurl=" +
                this.src
            );
        },
        // 数字转换
        smallToBig (money) {
            // 汉字的数字
            const cnNums = [
                "零",
                "壹",
                "贰",
                "叁",
                "肆",
                "伍",
                "陆",
                "柒",
                "捌",
                "玖",
            ];
            // 基本单位
            const cnIntRadice = ["", "拾", "佰", "仟"];
            // 对应整数部分扩展单位
            const cnIntUnits = ["", "万", "亿", "兆"];
            // 对应小数部分单位
            const cnDecUnits = ["角", "分"];
            // 整数金额时后面跟的字符
            const cnInteger = "整";
            // 整型完以后的单位
            const cnIntLast = "元";
            // 最大处理的数字
            const maxNum = 9999999999999999.99;
            // 金额整数部分
            let integerNum;
            // 金额小数部分
            let decimalNum;
            // 输出的中文金额字符串
            let chineseStr = "";
            // 分离金额后用的数组，预定义
            let parts;
            if (money === "") {
                return "";
            }
            money = parseFloat(money);
            if (money >= maxNum) {
                // 超出最大处理数字
                return "";
            }
            if (money === 0) {
                chineseStr = cnNums[0] + cnIntLast + cnInteger;
                return chineseStr;
            }
            // 转换为字符串
            money = money.toString();
            if (money.indexOf(".") === -1) {
                integerNum = money;

                decimalNum = "";
            } else {
                parts = money.split(".");
                integerNum = parts[0];
                decimalNum = parts[1].substr(0, 4);
            }
            // 获取整型部分转换
            if (parseInt(integerNum, 10) > 0) {
                let zeroCount = 0;
                const IntLen = integerNum.length;
                for (let i = 0; i < IntLen; i++) {
                    const n = integerNum.substr(i, 1);
                    const p = IntLen - i - 1;
                    const q = p / 4;
                    const m = p % 4;
                    if (n === "0") {
                        zeroCount++;
                    } else {
                        if (zeroCount > 0) {
                            chineseStr += cnNums[0];
                        }
                        // 归零
                        zeroCount = 0;
                        //alert(cnNums[parseInt(n)])
                        chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
                    }
                    if (m === 0 && zeroCount < 4) {
                        chineseStr += cnIntUnits[q];
                    }
                }
                chineseStr += cnIntLast;
            }
            // 小数部分
            if (decimalNum !== "") {
                const decLen = decimalNum.length;
                for (let i = 0; i < decLen; i++) {
                    const n = decimalNum.substr(i, 1);
                    if (n !== "0") {
                        chineseStr += cnNums[Number(n)] + cnDecUnits[i];
                    }
                }
            }
            if (chineseStr === "") {
                chineseStr += cnNums[0] + cnIntLast + cnInteger;
            } else if (decimalNum === "") {
                chineseStr += cnInteger;
            }
            return chineseStr;
        },
    },
};
</script>

<style lang="scss" scoped>
.Review {
    overflow: hidden;
    .fen {
        display: flex;
        justify-content: space-between;
        position: relative;

        .zuo {
            width: 72%;
            height: 600px;
            overflow-y: scroll;
            overflow-x: hidden;
            padding-bottom: 25px;
        }
        .zuo::-webkit-scrollbar {
            width: 5px;
            /*滚动条宽度*/
            height: 10px;
            /*滚动条高度*/
            background: transparent;
        }

        .zuo::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            border-radius: 10px;
            /*滚动条的背景区域的圆角*/
            background-color: #fff;
            /*滚动条的背景颜色*/
        }

        .zuo::-webkit-scrollbar-thumb {
            border-radius: 10px;
            /*滚动条的圆角*/
            height: 16px;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: transparent;
            /*滚动条的背景颜色*/
        }
        .you {
            width: 30%;
            padding-left: 15px;
            .block {
                height: 95% !important;
            }
            img {
                width: 100%;
                height: auto !important;
            }
        }
    }
    .from {
        position: relative;
    }
    .bottom {
        display: flex;
        // justify-content: space-between;
        position: absolute;
        width: 100%;
        left: -130px;
        bottom: 0;
        padding: 30px 40px;
        background: #fff;
        span {
            margin-right: 10px;
        }
        .status {
            margin-right: 40px;
        }
        .tijiao {
            background: #198bff !important;
            margin-left: 220px !important;
        }
    }
    .neirong {
        width: 600px;
        margin: 40px auto;
        border: 1px solid #ccc;
    }
    .title {
        display: flex;
        line-height: 32px;
        padding: 10px 20px;
    }
    .danzi {
        margin-left: 20px;
    }
    .lie {
        display: flex;
        margin-left: 20px;
        line-height: 47px;
        text-indent: 1.5em;
        position: relative;
        div {
            width: 200px;
            background: #eef6fe;
            border-left: 1px solid #ccc;
            border-top: 1px solid #ccc;
        }
        span {
            width: 100%;
            border-left: 1px solid #ccc;
            border-top: 1px solid #ccc;
        }
    }
    .lies {
        div {
            background: #fff;
            border: 0px;
            margin-left: -22px;
            border-left: 1px solid #ccc;
        }
    }
    .copy {
        margin-left: 240px !important;
    }
    .p {
        position: absolute;
        left: 230px;
        top: 0;
        text-align: left !important;
    }
}
</style>
<style lang="scss">
.Review {
    .success {
        color: #fff;
    }
    .el-dialog__header {
        background: #0075f7;
    }
    .el-dialog__title {
        color: #ffffff;
    }
    .el-button--primary {
        background: #2cc907 !important;
        // height: 30px !important;
        border: 0;
        margin: 0 !important;
        margin-left: 20px !important;
    }
    .lies .el-input__inner {
        width: 80px;
        border: 0;
        padding: 0;
    }
}
</style>