<template>
    <div class="orderFirm">
        <!-- 搜索框 -->
        <div class="fromkuang padd20 borbut20">
            <div class="kuang">
                <div class="dan">
                    <span class="span">开始时间：</span>
                    <el-date-picker v-model="form.start_created_at" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
                </div>
                <div class="dan">
                    <span class="span">结束时间：</span>
                    <el-date-picker v-model="form.end_created_at" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
                </div>
                <!-- <div class="dan">
                    <span class="span">订单状态：</span>
                    <el-select v-model="form.order_status" placeholder="请选择" clearable>
                        <el-option label="处理中" value="1" size="120px"> </el-option>
                        <el-option label="处理完成" value="2" size="120px"> </el-option>
                        <el-option label="未处理" value="3" size="120px"> </el-option>
                    </el-select>
                </div> -->

                <div class="dan">
                    <span class="span">选择企业：</span>
                    <el-select v-model="form.corporate_account_id" placeholder="请输入企业" filterable remote clearable @change="currentSel" reserve-keyword :remote-method="Tolikeqy">
                        <div v-infinite-scroll="qiyeload" style="" class="flock-load">
                            <el-option v-for="item in qiyelist" :key="item.id" :label="item.corporate_account_name" :value="item.id">
                            </el-option>
                        </div>
                    </el-select>
                </div>

                <div class="dan">
                    <span class="span">收款账户：</span>
                    <el-select v-model="form.bank_card_id" filterable placeholder="请输入账户" remote clearable @change="currentSel" reserve-keyword :remote-method="Tolikezh">
                        <div v-infinite-scroll="zhanghuload" style="" class="flock-load">
                            <el-option v-for="item in bankcard" :key="item.id" :label="item.account_name" :value="item.id">
                            </el-option>
                        </div>
                    </el-select>
                </div>
                <div class="dan">
                    <span class="span">订单ID：</span>
                    <el-input placeholder="请输入内容" v-model="form.id" clearable>
                    </el-input>
                </div>
            </div>
            <!-- 隐藏的搜索框 -->
            <div class="zhankai">
                <!-- <div class="kuang UnfoldorPack" :class="UnfoldorPack ? 'active' : ''"> -->
                <div class="kuang UnfoldorPack active">

                    <!-- <div class="dan qundan">
                        <span class="span qunspan">群名称：</span>
                        <el-input placeholder="请输入内容" v-model="form.company_id" clearable>
                        </el-input>
                    </div> -->
                    <div class="dan qundan">
                        <span class="span qunspan">群名称：</span>
                        <el-select v-model="form.company_id" filterable placeholder="请输入群名称" remote clearable @change="currentSel" reserve-keyword :remote-method="Tolikesearch">
                            <div v-infinite-scroll="qunload" style="" class="flock-load">
                                <el-option v-for="item in qunlist" :key="item.value" :label="item.company_name" :value="item.id">
                                </el-option>
                            </div>
                        </el-select>
                    </div>
                    <!-- <div class="dan">
                        <span class="span">待定：</span>
                        <el-select v-model="value" placeholder="请选择">
                            <div style="" class="flock-load">
                                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </div>

                        </el-select>
                    </div>  -->
                    <div class="dan">
                        <span class="span">支付方式：</span>
                        <el-select v-model="form.is_payment_wat" placeholder="请选择" clearable>
                            <el-option label="预付款" value="1" size="120px"> </el-option>
                            <el-option label="垫款" value="2" size="120px"> </el-option>
                        </el-select>
                    </div>
                </div>
                <!-- <div class="unfold">
                    <span @click="onClickUnfold">
                        {{UnfoldorPack ? '收起' : '展开更多筛选'}} <i class="el-icon-arrow-down" :class="UnfoldorPack ? 'ioi' : ''"></i>
                    </span>

                </div> -->
            </div>
            <div class="cha">
                <el-button type="primary" icon="el-icon-search" @click="onButtonInquire()">查询</el-button><br />
                <el-button class="Shuarefresh" @click="$router.go(0)" icon='el-icon-refresh-right' style="width:120px;margin-left:0;margin-top:10px">重置数据</el-button>
                <!-- <span @click="$router.go(0)">重置数据</span> -->
                <!-- <span @click="reset()">重置数据</span> -->
            </div>

        </div>
        <div class="finanOrderStatus padd20 borbut20 ">
            <div class="finantitle">订单状态：</div>
            <div class="statusList">
                <div class="dan" v-for="(item,key) in OrderStatus" :key="key" :class="form.order_status == item.value ? 'active' : ''" @click="onOrderStatus(item)">{{item.label}}</div>
            </div>
        </div>
        <!-- 订单列表 -->
        <div class="order padd20">
            <!-- 按钮 -->
            <div class="anniu">
                <div class="ddv">订单列表</div>
                <el-button type="primary" icon="el-icon-plus" @click="found($event)" id="Recharge">创建新订单</el-button>
                <div class="jiaoban">
                    <el-input v-model="jiaobaninput" placeholder="请输入交班人员姓名"></el-input>
                    <el-button type="primary" @click="onClassJiaoban()">交班</el-button>
                </div>
                <div class="lvtotal">总条数：{{lvtotal}} 条</div>
            </div>
            <div v-loading="fullscreenLoading">
                <div class="list" v-for='(item,index) in List' :key="item.index" :id="index">
                    <div class="title tits" :style="'background:' + (item.order_status==1 ? '#e5f3f7' : item.order_status==2 ? '#e6f1e1' : item.order_status==3 ? '#f7f0e5' : '')">
                        <div>
                            <span>处理人员：</span><span style="color:#f00">{{item.class_name ? item.class_name : ''}}</span>
                        </div>
                        <div>
                            <span>订单编号：</span><span>{{item.order_num}}</span>
                        </div>
                        <div>
                            <span>下单时间：</span><span>{{item.created_at}}</span>
                        </div>
                        <div v-if="item.order_status ==2">
                            <span>订单完成时间：{{item.updated_at}}</span>
                        </div>
                        <div v-else-if="item.user_id==0">
                            <span v-if="item.order_status==1">订单处理时间：{{item.updated_at}}</span>
                            <span v-if="item.order_status==3">订单处理时间：后台下单</span>
                        </div>
                        <div v-else>
                            <span v-if="item.order_status==1">订单处理时间：{{item.predict_finish_end_time}}</span>
                            <span v-if="item.order_status==3">准时宝处理时间：{{dateFtt("yyyy-MM-dd hh:mm:ss",new Date(item.predict_finish_start_time))}} - {{dateFtt("hh:mm:ss",new Date(item.predict_finish_end_time))}}</span>
                        </div>
                        <div>
                            <span>订单状态：</span><span>
                                <el-tag type="" effect="plain" v-if="item.order_status==1">处理中</el-tag>
                                <el-tag type="success" effect="plain" v-if="item.order_status==2">处理完成</el-tag>
                                <el-tag type="warning" effect="plain" v-if="item.order_status==3">未处理</el-tag>
                            </span>
                        </div>
                    </div>
                    <!--  -->
                    <div class="center">
                        <!--  -->
                        <div class="one">
                            <div class="flex">
                                <div>开户行</div>
                                <p>{{item.bank_card_msg.where_it_is}}</p>
                            </div>
                            <div class="flex">

                                <div>收款人姓名</div>
                                <p>{{item.bank_card_msg.account_name}}</p>
                            </div>
                            <div class="flex">
                                <div>银行卡号</div>
                                <p>{{item.bank_card_msg.card_number}}</p>
                            </div>
                            <div class="flex">
                                <div>收款账户类型</div>
                                <p v-if="item.bank_card_msg.card_status==2">私户</p>
                                <p v-if="item.bank_card_msg.card_status==1">公户</p>
                            </div>
                        </div>
                        <div class="one">
                            <div class="flex rig">
                                <div>群名称</div>
                                <p>{{item.company_msg.company_name}}</p>
                            </div>
                            <div class="flex">
                                <div>企业名称</div>
                                <p>{{item.corporate_account_msg.corporate_account_name}}</p>
                            </div>
                            <!-- <div class="flex">
                                    <div>运营类型</div>
                                    <p v-if="item.is_operating_type==2">代运营</p>
                                    <p v-if="item.is_operating_type==1">自运营</p>
                                </div> -->
                        </div>

                        <div class="one">
                            <div class="flex jin">
                                <div>打款金额</div>
                                <p> {{ThousandSeparator(item.order_price)}}</p>
                                <p></p>
                            </div>
                            <div class="flex">
                                <div>支付方式</div>
                                <p v-if="item.is_payment_wat==2">垫款</p>
                                <p v-if="item.is_payment_wat==1">预付款</p>
                            </div>
                        </div>
                        <!--  -->

                        <!--  -->
                        <div class="auto">
                            <el-button type="warning" id="Review" @click="onReview(item.id,$event,item.order_status)">审核订单</el-button>
                        </div>
                    </div>
                    <!--  -->
                    <div class="title tit">
                        <div class="beizhu">
                            <span>备注：</span>
                            <el-input type="textarea" :rows="2" placeholder="请设置备注" @change="blur(item)" v-model="item.order_note" class="text">
                            </el-input>

                        </div>
                    </div>
                </div>
            </div>
            <div class="fenye">
                <el-pagination background :page-size="per_page" @size-change="handleSizeChange" :page-sizes="[10, 20, 50]" layout="sizes,prev, pager, next" :total="lvtotal" @current-change="DialogCurrentChange" :current-page.sync="paramPage" hide-on-single-page>
                </el-pagination>
            </div>
        </div>
        <el-empty v-if="this.List==''" description="暂无数据"></el-empty>
        <!-- 显示隐藏弹窗 -->
        <div>
            <Review ref="Review" :id="Compiles_id" :msg="activeName" @shenhe="shenhe"></Review>
            <Recharge ref="Recharge" :msg="activeName" @xinzeng="xinzeng" v-if="activeName == 'Recharge'"></Recharge>
        </div>
        <!-- 分页 -->

    </div>
</template>

<script>
import Review from "./Review.vue";
import Recharge from "./Recharge.vue";
export default {
    data () {
        return {
            UnfoldorPack: false, //展开或者收起搜索
            input: "", //订单编号
            form: {
                order_status: "3", //订单状态
                is_payment_wat: "", //支付方式
                start_created_at: "", //订单开始日期
                end_created_at: "", //订单结束时间
                corporate_account_id: "", //企业id
                company_id: "", //群id
                id: "", //订单id
                bank_card_id: "", //获取银行卡
            },
            options: [
                {
                    value: "选项1",
                    label: "黄金糕",
                },
                {
                    value: "选项2",
                    label: "双皮奶",
                },
                {
                    value: "选项3",
                    label: "蚵仔煎",
                },
                {
                    value: "选项4",
                    label: "龙须面",
                },
                {
                    value: "选项5",
                    label: "北京烤鸭",
                },
            ],
            value: "",
            List: "", //订单列表
            activeName: "", //控制显示审核订单
            Compiles_id: "", //订单id
            page: 1, //页码
            qiyelist: "", //企业列表
            bankcard: "", //银行卡列表
            // 分页
            per_page: 10, //一页多少条
            lvtotal: null, //共几条数据
            paramPage: 1,//当前页
            data: {}, //订单详情
            qunlist: "", //群列表
            PageTopCompanyList: 1, //群页码
            PageTopBankCardList: 1, //银行卡页码
            PageTopCorporateAccountList: 1, //企业页码
            yczh: "", //远程账户
            qunid: "", //远程群
            ycqiye: "", //远程充值
            fullscreenLoading: true,
            paramData: {
                order_status: "3", //订单状态
            },//列表搜索参数
            OrderStatus: [//订单状态列表
                {
                    value: '',
                    label: '全部'
                }, {
                    value: '2',
                    label: '处理完成'
                }, {
                    value: '1',
                    label: '处理中'
                }, {
                    value: '3',
                    label: '未处理'
                },
            ],
            jiaobaninput: localStorage.getItem('CLASS-NAME'),
        };
    },

    mounted () {
        this.inquire()//获取订单列表
        // this.getTopOrderList(); //获取订单列表
        this.animateWidth(); //获取企业列表
        this.getTopBankCardList(); //获取银行卡列表
        this.getTopCompanyList(); //获取群列表
    },
    components: {
        Review,
        Recharge,
    },
    methods: {
        //交班
        onClassJiaoban () {
            if (this.jiaobaninput) {
                localStorage.setItem("CLASS-NAME", this.jiaobaninput);  //账户名
                this.$message({ message: '交班成功', type: 'success' });
            } else {
                this.$message({ message: '请填写交班人员姓名', type: 'warning' });
            }
        },
        //每页多少条触发条件
        handleSizeChange (val) {
            this.per_page = val
            this.inquire()
        },
        //订单状态点击
        onOrderStatus (item) {
            this.form.order_status = item.value
            this.onButtonInquire()
        },
        // 群搜索
        currentSel (selVal) {
            // console.log(selVal);
        },
        //搜索发起请求 传入值为当前select输入的值
        Tolikesearch (query) {
            this.qunid = query;
            var param = {
                page: 1,
                company_name: query,
            };
            this.$service.get(this.$api.getTopCompanyList, param, (res) => {
                if (res.code == "200") {
                    this.qunlist = res.data.data;
                }
            });
        },
        Tolikezh (query) {
            this.yczh = query;
            var param = {
                page: 1,
                account_name: query,
            };
            this.$service.get(this.$api.getTopBankCardList, param, (res) => {
                if (res.code == "200") {
                    // console.log(res.data.data);
                    this.bankcard = res.data.data;
                }
            });
        },
        // 企业
        Tolikeqy (query) {
            this.ycqiye = query;
            var param = {
                page: 1,
                corporate_account_name: query,
            };
            this.$service.get(
                this.$api.getTopCorporateAccountList,
                param,
                (res) => {
                    if (res.code == "200") {
                        this.qiyelist = res.data.data;
                    }
                }
            );
        },
        //审核订单
        onReview (id, e, order_status) {
            this.Compiles_id = id;
            var activeName = e.currentTarget.id;
            var param = {
                order_id: id,
            };
            this.$service.get(this.$api.getTopOrderDetail, param, (res) => {
                if (res.code == "200") {
                    // this.order_detail = res.data.order_detail;
                    this.data = res.data;
                    this.activeName = activeName;
                    this.$refs.Review.onzhanghu(res.data)
                }
            });
        },
        //获取群列表
        getTopCompanyList () {
            var param = {
                page: 1,
            };
            this.$service.get(this.$api.getTopCompanyList, param, (res) => {
                if (res.code == "200") {
                    this.qunlist = res.data.data;
                }
            });
        },
        // 审核组件回传
        shenhe () {
            this.inquire()//获取订单列表
            // this.getTopOrderList(); //获取订单列表
            this.activeName = "";
        },
        // 控制隐藏搜索框显示隐藏
        onClickUnfold () {
            this.UnfoldorPack = !this.UnfoldorPack;
        },
        //获取企业列表
        animateWidth () {
            var param = {
                page: 1,
            };
            this.$service.get(
                this.$api.getTopCorporateAccountList,
                param,
                (res) => {
                    if (res.code == "200") {
                        this.qiyelist = res.data.data;
                    }
                }
            );
        },
        //获取银行卡列表
        getTopBankCardList () {
            var param = {
                page: 1,
            };
            this.$service.get(this.$api.getTopBankCardList, param, (res) => {
                if (res.code == "200") {
                    this.bankcard = res.data.data;
                }
            });
        },
        //企业触底事件
        qiyeload () {
            if (this.ycqiye) {
                return;
            }
            this.PageTopCorporateAccountList++;
            var param = {
                page: this.PageTopCorporateAccountList,
            };
            this.$service.get(
                this.$api.getTopCorporateAccountList,
                param,
                (res) => {
                    var data = res.data.data;
                    if (res.code == "200") {
                        if (res.data.per_page == 10 || res.data.per_page > 0) {
                            var bankcard = this.qiyelist;
                            data.forEach(function (item, index) {
                                bankcard.push(item);
                            });
                            this.qiyelist = bankcard;
                        } else {
                            this.PageTopCorporateAccountList--;
                        }
                    }
                }
            );
        },
        //群触底事件
        qunload () {
            if (this.qunid) {
                return;
            }
            this.PageTopCompanyList++;
            var param = {
                page: this.PageTopCompanyList,
            };
            this.$service.get(this.$api.getTopCompanyList, param, (res) => {
                var data = res.data.data;
                if (res.code == "200") {
                    if (res.data.per_page == 10 || res.data.per_page > 0) {
                        var qunlist = this.qunlist;
                        data.forEach(function (item, index) {
                            qunlist.push(item);
                        });
                        this.qunlist = qunlist;
                    } else {
                        this.PageTopCompanyList--;
                    }
                }
            });
        },
        //银行卡触底事件
        zhanghuload () {
            if (this.yczh) {
                return;
            }
            this.PageTopBankCardList++;
            var param = {
                page: this.PageTopBankCardList,
            };
            this.$service.get(this.$api.getTopBankCardList, param, (res) => {
                var data = res.data.data;
                if (res.code == "200") {
                    if (res.data.data.length == 10 || res.data.data.length > 0) {
                        var bankcard = this.bankcard;
                        data.forEach(function (item, index) {
                            bankcard.push(item);
                        });
                        this.bankcard = bankcard;
                    } else {
                        this.PageTopBankCardList--;
                    }
                }
            });
        },
        // 清空群
        // qkqun() {
        //     this.getTopCompanyList(); //获取群列表
        // },
        // qkzhanghu() {
        //     this.getTopBankCardList(); //获取银行卡列表
        // },
        // qkqiye() {
        //     this.animateWidth(); //获取企业列表
        // },
        //加载
        // lodin() {
        //     this.fullscreenLoading = this.$loading({
        //         lock: true,
        //         text: "Loading",
        //         spinner: "el-icon-loading",
        //         background: "rgba(0, 0, 0, 0.7)",
        //     });
        // },
        // 获取订单列表
        // getTopOrderList() {
        //     this.fullscreenLoading = true;
        //     var param = {
        //         page: 1,
        //     };
        //     this.$service.get(this.$api.getTopOrderList, param, (res) => {
        //         this.fullscreenLoading = false;
        //         if (res.code == "200") {
        //             this.List = res.data.data;
        //             this.lvtotal = res.data.total;
        //             // console.log(this.lvtotal);
        //             // console.log(this.List);
        //         }
        //     });
        // },
        // 备注输入框
        blur (item) {
            var param = {
                order_id: item.id,
                order_note: item.order_note,
            };
            this.$service.post(this.$api.updateOrderNote, param, (res) => {
                if (res.code == "200") {
                }
            });
        },
        // 搜索触发
        onButtonInquire () {
            this.paramData = {
                order_status: this.form.order_status, //订单状态
                is_payment_wat: this.form.is_payment_wat, //支付方式
                start_created_at: this.form.start_created_at, //订单开始日期
                end_created_at: this.form.end_created_at, //订单结束时间
                corporate_account_id: this.form.corporate_account_id, //企业id
                company_id: this.form.company_id, //群id
                id: this.form.id, //订单id
                bank_card_id: this.form.bank_card_id, //获取银行卡
            }
            this.inquire(1)
        },
        // 分页
        DialogCurrentChange (val) {
            this.paramPage = val
            this.inquire()
        },
        // 查询
        inquire () {
            this.fullscreenLoading = true;
            this.List = [];
            var param = this.paramData;
            param.page = this.paramPage
            param.per_page = this.per_page
            this.$service.get(this.$api.getTopOrderList, param, (res) => {
                this.fullscreenLoading = false;
                if (res.code == "200") {
                    this.List = res.data.data;
                    this.lvtotal = res.data.total;
                }
            });
        },
        // 分页
        // DialogCurrentChange(val) {
        //     // console.log(val);
        //     this.List = [];
        //     var param = {
        //         page: val,
        //     };
        //     this.$service.get(this.$api.getTopOrderList, param, (res) => {
        //         if (res.code == "200") {
        //             this.List = res.data.data;
        //             this.lvtotal = res.data.total;
        //         }
        //     });
        // },
        // 创建订单
        found (e) {
            this.activeName = e.currentTarget.id;
        },
        // 新增订单
        xinzeng () {
            this.activeName = "";
            this.inquire()
            // this.getTopOrderList();
        },
        //重置数据
        reset () {
            this.form = {
                order_status: "3", //订单状态
                is_payment_wat: "", //支付方式
                start_created_at: "", //订单开始日期
                end_created_at: "", //订单结束时间
                corporate_account_id: "", //企业id
                company_id: "", //群id
                id: "", //订单id
                bank_card_id: "", //获取银行卡
            };
        },
        // 金额转换
        smallToBig (money) {
            // 汉字的数字
            const cnNums = [
                "零",
                "壹",
                "贰",
                "叁",
                "肆",
                "伍",
                "陆",
                "柒",
                "捌",
                "玖",
            ];
            // 基本单位
            const cnIntRadice = ["", "拾", "佰", "仟"];
            // 对应整数部分扩展单位
            const cnIntUnits = ["", "万", "亿", "兆"];
            // 对应小数部分单位
            const cnDecUnits = ["角", "分"];
            // 整数金额时后面跟的字符
            const cnInteger = "整";
            // 整型完以后的单位
            const cnIntLast = "元";
            // 最大处理的数字
            const maxNum = 9999999999999999.99;
            // 金额整数部分
            let integerNum;
            // 金额小数部分
            let decimalNum;
            // 输出的中文金额字符串
            let chineseStr = "";
            // 分离金额后用的数组，预定义
            let parts;
            if (money === "") {
                return "";
            }
            money = parseFloat(money);
            if (money >= maxNum) {
                // 超出最大处理数字
                return "";
            }
            if (money === 0) {
                chineseStr = cnNums[0] + cnIntLast + cnInteger;
                return chineseStr;
            }
            // 转换为字符串
            money = money.toString();
            if (money.indexOf(".") === -1) {
                integerNum = money;

                decimalNum = "";
            } else {
                parts = money.split(".");
                integerNum = parts[0];
                decimalNum = parts[1].substr(0, 4);
            }
            // 获取整型部分转换
            if (parseInt(integerNum, 10) > 0) {
                let zeroCount = 0;
                const IntLen = integerNum.length;
                for (let i = 0; i < IntLen; i++) {
                    const n = integerNum.substr(i, 1);
                    const p = IntLen - i - 1;
                    const q = p / 4;
                    const m = p % 4;
                    if (n === "0") {
                        zeroCount++;
                    } else {
                        if (zeroCount > 0) {
                            chineseStr += cnNums[0];
                        }
                        // 归零
                        zeroCount = 0;
                        //alert(cnNums[parseInt(n)])
                        chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
                    }
                    if (m === 0 && zeroCount < 4) {
                        chineseStr += cnIntUnits[q];
                    }
                }
                chineseStr += cnIntLast;
            }
            // 小数部分
            if (decimalNum !== "") {
                const decLen = decimalNum.length;
                for (let i = 0; i < decLen; i++) {
                    const n = decimalNum.substr(i, 1);
                    if (n !== "0") {
                        chineseStr += cnNums[Number(n)] + cnDecUnits[i];
                    }
                }
            }
            if (chineseStr === "") {
                chineseStr += cnNums[0] + cnIntLast + cnInteger;
            } else if (decimalNum === "") {
                chineseStr += cnInteger;
            }
            return chineseStr;
        },
    },
};
</script>

<style lang="scss" scoped>
.orderFirm {
    .span {
        padding-right: 25px;
        text-align: right;
    }

    .kuang {
        padding-right: 150px;
        display: flex;
        flex-wrap: wrap;
        .dan {
            display: flex;
            align-items: center;
            margin-left: 20px;
            // margin-bottom: 10px;
            &:first-child {
                margin-left: 0;
            }
            .span {
                width: 80px;
                padding-right: 0;
                display: block;
            }
        }
    }
    // .dan {
    //     margin-left: 25px;
    // }
    .dspan {
        margin-left: 40px;
        margin-right: 30px;
    }
    .qunspan {
        margin-right: 0px;
    }
    .zhankai {
        justify-content: center;
        text-align: center;
        margin-top: 20px;
        .UnfoldorPack {
            overflow: hidden;
            height: 0;
            transition: 0.6s;
            &.active {
                height: 35px;
            }
        }
        .unfold {
            color: #0075f7;
            cursor: pointer;
        }
        .ioi {
            transition: 0.6s;
            transform: rotate(-180deg);
        }
    }
    .kai {
        display: flex;
        text-align: left !important;
    }
    .dd {
        display: flex;
    }
    .anniu {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 20px;
    }

    .list {
        border: 1px solid #ccc;
        margin-bottom: 25px;
    }
    .title {
        display: flex;
        line-height: 32px;
        justify-content: space-between;
        border-bottom: 1px solid #ccc;
        margin: 0 auto;
        padding: 10px 20px;
        // div {
        //     width: 100%;
        // }
        div:nth-child(1) {
            // width: 200px;
            // margin-left: 90px;
        }
        // div:nth-child(2) {
        //     margin-left: 40px;
        // }
        // div:nth-child(3) {
        //     text-align: center;
        // }
        .beizhu {
            display: flex;
            text-align: left;
            width: 100%;
            span {
                width: 50px;
            }
            div {
                margin-left: 0px;
            }
        }
    }
    .tits {
        background: #eef6fe;
    }
    .tit {
        border-bottom: 0px solid #ccc;
        border-top: 1px solid #ccc;
    }
    .center {
        display: flex;
        margin-left: 20px;
        margin-right: 20px;
        .one {
            width: 450px;
            border-right: 1px solid #ccc;
        }
        .flex {
            display: flex;
            line-height: 33px;
            div {
                width: 180px;
                text-indent: 2em;
                font-weight: bold;
            }
            p {
                width: 100%;
                text-indent: 2em;
            }
        }
        .jin p {
            width: 50%;
        }
        .flexs {
            display: flex;
            div {
                width: 147px !important;
            }
        }
    }
    .auto {
        margin: 0 auto;
        margin-top: 50px;
    }
    .hei {
        height: 34px;
    }
    .rig p {
        border-right: 0px !important;
    }
    .flock-load {
        max-height: 200px;
        width: 100%;
        overflow-y: auto;
        display: inline-block;
    }
    .fenye {
        text-align: right;
        margin-top: 25px;
        margin-right: 50px;
    }
    .fromkuang {
        position: relative;
    }
    .cha {
        position: absolute;
        top: 20px;
        right: 25px;
        text-align: center;
        span {
            display: block;
            margin-top: 10px;
            cursor: pointer;
            color: #0075f7;
            font-weight: bold;
        }
        .el-button--primary {
            margin: 0 !important;
        }
    }
    .ddv {
        line-height: 34px;
    }
}
</style>
<style lang="scss">
.orderFirm {
    .el-date-editor .el-range-separator {
        width: 30%;
    }

    .el-select {
        width: 180px;
        &.el-select--mini {
            width: auto;
        }
    }
    .el-input {
        width: 180px;
    }
    .my-label {
        background: #e1f3d8;
    }

    .my-content {
        background: #fde2e2;
    }
    .el-button--primary {
        width: 120px;
        margin-left: 30px;
        margin-right: 100px;
        background: #0075f7;
    }
    .el-descriptions .is-bordered td,
    .el-descriptions .is-bordered th {
        border: 1px solid #ccc;
    }
    .el-input__icon {
        line-height: 35px;
    }
    .el-textarea__inner {
        max-width: 100%;
        max-height: 100px;
    }
    .el-textarea__inner {
        border: 0px;
    }
    .el-select-dropdown {
        max-width: 150px;
    }
    .el-loading-spinner {
        margin-top: 130px;
    }
}
.zhanghuselect {
    .el-select-dropdown__item {
        padding: 0px;
        padding-left: 10px;
    }
}
</style>