<template>
    <div class="Rechargea">
        <div class="from">
            <el-dialog title="账户充值" :visible.sync="zhanghu" @close="closeDialog()" :closeOnClickModal='false' width="800PX" prop-class="dialog">
                <!-- <div class="chuangjian">新增账户</div> -->
                <el-form :model="form">
                    <!-- <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            公户存档:
                        </div>
                        <el-input v-model="form.family_archive" autocomplete="off" placeholder="请输入公户存档"></el-input>
                    </el-form-item> -->
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            选择群
                        </div>
                        <el-select v-model="jieshou" value-key="id" filterable placeholder="请输入群" remote clearable reserve-keyword :remote-method="Tolikequn" @change="blur(form.company_id)" popper-class='flock-select'>
                            <div v-infinite-scroll="load" style="" class="flock-load">
                                <el-option :label="item.company_name" :value="item" v-for='(item,index)  in inputD' :key="index"></el-option>
                            </div>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai kuan">
                            <span class="xing">* </span>
                            转账截图
                        </div>
                        <!-- <el-upload :action="$api.upload + '?token=' + '7ce563cc4f49544991de6ed0ac743bbe'" list-type="picture-card" :on-preview="handlePictureCardPreview" :on-success="onSuccessupload" :on-remove="handleRemove">
                            <i class="el-icon-plus"></i>
                        </el-upload> -->
                        <el-upload class="upload-demo" drag :action="$api.upload + '?token=' + '7ce563cc4f49544991de6ed0ac743bbe'" list-type="picture-card" :on-preview="handlePictureCardPreview" :on-success="onSuccessupload" :on-remove="handleRemove" multiple>
                            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                            <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
                        </el-upload>
                        <el-dialog :visible.sync="dialogVisible">
                            <img width="100%" :src="dialogImageUrl" alt="">
                        </el-dialog>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            转账人民币
                        </div>
                        <el-input v-model="form.order_price" autocomplete="off" placeholder="图片识别的人民币"></el-input>
                        <p style="margin-left:10px" v-if="jiazhai"><i class="el-icon-loading"></i> <span style="margin-left:5px">识别中</span></p>
                        <p style="margin-left:15px" v-else> <span style="margin-left:5px" :style="classObje(form.order_price)">{{kong}}</span></p>
                        <p style="margin-left:15px" v-if="form.order_price && card_status && is_operating_type">
                            <span v-if="is_operating_type==2" style="color:red ; font-size:18px;">参考到账广告币：{{
                                cz[0].advertiser.advertiser_full_name.search('快手') != -1 || cz[0].advertiser.advertiser_name.search('快手') != -1  ? towNumber(form.order_price*Number(arr[[card_status,is_operating_type].join("")])) : 
                                towNumber(form.order_price/Number(arr[[card_status,is_operating_type].join("")]))
                            }} </span>
                            <!-- <span v-if="is_operating_type==2" style="color:red ; font-size:18px;">参考到账广告币：{{towNumber(form.order_price/Number(arr[[card_status,is_operating_type].join("")]))}} </span> -->
                            <span v-else style="color:red ; font-size:18px;">参考到账广告币：{{towNumber(form.order_price*Number(arr[[card_status,is_operating_type].join("")]))}} </span>
                            <!-- <span style="margin-left:5px;　color:red ; font-size:18px;">参考到账广告币：{{towNumber(form.order_price*(1 + Number(arr[[card_status,is_operating_type].join("")])))}} </span> -->
                        </p>
                    </el-form-item>

                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            收款账户
                        </div>
                        <el-select v-model="zh" value-key="id" filterable remote clearable reserve-keyword :remote-method="Tolikezh" placeholder="图片识别出的收款人，如果图片未匹配到收款账户，这里可选择" @change="shoukuan(form.bank_card_id)" popper-class='flock-select'>
                            <div v-infinite-scroll="loads" style="" class="flock-load">
                                <el-option :label="item.account_name" :value="item" v-for='(item,index) in card' :key="index"></el-option>
                            </div>
                        </el-select>
                    </el-form-item>
                    <!-- 增加账户 -->
                    <div class="zeng" v-for='(item,index) in groupList' :key="index">
                        <el-form-item label="" :label-width="formLabelWidth">
                            <div class="kuai">
                                <span class="xing">* </span>
                                充值账户
                            </div>
                            <el-select v-model="cz[index]" value-key="id" placeholder="请选择充值账户" popper-class='flock-select' filterable remote reserve-keyword :remote-method="Tolikeczzh" @change="chongzhizh(index,$event)">
                                <!-- <div v-infinite-scroll="loadt" style="" class="flock-load"> -->
                                <el-option :label="item.agent_account_name + ' _ ' + item.agent_account + ' _ ' + (item.is_operating_type == 1 ? '自运营' : item.is_operating_type == 2 ? '代运营' : item.is_operating_type == 3 ? '渠道' : item.is_operating_type == 4 ? 'W渠道' : '')" :value="item" v-for='(item,index) in zhlist' :key="index"></el-option>
                                <!-- </div> -->
                            </el-select>
                            <div class="chong">
                                充值账户({{index+1}}) <span @click="dele(index)" v-if="index!=0">删除</span>
                            </div>
                        </el-form-item>
                        <el-form-item label="" :label-width="formLabelWidth">
                            <div class="kuai">
                                <span class="xing">* </span>
                                实际到账广告币
                            </div>
                            <el-input v-model="item.user_record_price" autocomplete="off" placeholder="根据选择的充值帐户、转账账户（对公，对私）的返点进行计算" class="in"></el-input>
                        </el-form-item>
                    </div>
                    <div class="zengjia" @click="addzhanghu()">
                        + 增加账户
                    </div>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <!-- <el-button @click="quxiao" class="success button">取 消</el-button> -->
                    <el-button type="primary" @click="tijiao" class="tijiao primary button">提 交</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
export default {
    props: ["msg"],
    data () {
        return {
            zhanghu: true, //操控弹窗展示
            form: {
                // agent_account: "",
                // agent_account_name: "",
                order_price: "", // 收款金额
                upload_img: "", //图片路径
                file_id: "", //图片id
                company_id: "", // 群id
                corporate_account_id: "", //企业ID
                // zhid: "", // 账户id
                // guanggaobi: "", //实际到账广告币
                bank_card_id: "", //收款银行卡id
                card_status: "", //收款账户类型
            },
            jieshou: "", //为获取企业id
            zh: "", //账户类型
            card_status: "", //账户类型最终
            cz: [], //充值账户类型
            is_operating_type: "", //自运营代运营
            formLabelWidth: "140px",
            activeName: "", //显示组件
            inputD: [],
            //
            dialogImageUrl: "",
            dialogVisible: false,

            disabled: false,
            zhlist: "", //群下账户列表
            groupList: [
                //  新增账户
                {
                    agent_account_id: "", // 账户
                    account_port_id: "", // 账户
                    user_record_price: "", //实际到账
                    is_operating_type: "", //充值账户类型
                },
            ],
            card: [], //银行卡
            jiazhai: "", //加载
            kong: "", //加载失败
            yczh: "", //远程账户
            qunid: "", //远程群
            ycchongzhi: "", //远程充值
            PageTopCompanyList: 1, //群页码
            PageTopBankCardList: 1, //银行卡页码
            PageTopAgentAccountList: 1, //账户页码
            arr: [],
        };
    },
    methods: {
        towNumber (val) {
            return val.toFixed(2);
        },
        // 远程搜索群
        Tolikequn (query) {
            this.qunid = query;
            var param = {
                page: 1,
                company_name: query,
            };
            this.$service.get(this.$api.getTopCompanyList, param, (res) => {
                if (res.code == "200") {
                    this.inputD = res.data.data;
                }
            });
        },
        // 远程搜索账户
        Tolikezh (query) {
            this.yczh = query;
            var param = {
                page: 1,
                account_name: query,
            };
            this.$service.get(this.$api.getTopBankCardList, param, (res) => {
                this.card = [];
                var data = res.data.data;
                this.card = data;
            });
        },
        // 远程搜索充值账户
        Tolikeczzh (query) {
            if (this.form.company_id == '') { return this.$message({ message: '请先选择群', type: 'warning' }); }
            this.ycchongzhi = query;
            this.zhlist = [];
            this.ycchongzhi = query;
            var param = {
                page: 1,
                agent_account: query,
                company_id: this.form.company_id,
            };
            this.$service.get(this.$api.getTopAgentAccountList, param, (res) => {
                var data = res.data.data;
                this.zhlist = data;
            }
            );
        },
        // 清空群
        // shiqu() {
        //     PageTopCompanyList = 1;
        //     PageTopBankCardList = 1;
        //     PageTopAgentAccountList = 1;
        // },
        //获取群
        getTopCompanyList () {
            var param = {
                page: 1,
            };
            this.$service.get(this.$api.getTopCompanyList, param, (res) => {
                if (res.code == "200") {
                    this.inputD = res.data.data;
                }
            });
        },
        //选择群触底事件
        load () {
            if (this.qunid) {
                return;
            }
            this.PageTopCompanyList++;
            var param = {
                page: this.PageTopCompanyList,
            };
            this.$service.get(this.$api.getTopCompanyList, param, (res) => {
                // console.log(res);
                var data = res.data.data;
                if (res.code == "200") {
                    if (res.data.per_page == 10 || res.data.per_page > 0) {
                        var inputD = this.inputD;
                        data.forEach(function (item, index) {
                            inputD.push(item);
                        });
                        this.inputD = inputD;
                    } else {
                        this.PageTopCompanyList--;
                    }
                }
            });
        },
        //选择银行卡触底事件
        loads () {
            if (this.yczh) {
                return;
            }
            this.PageTopBankCardList++;
            var param = {
                page: this.PageTopBankCardList,
            };
            this.$service.get(this.$api.getTopBankCardList, param, (res) => {
                var data = res.data.data;
                if (res.code == "200") {
                    if (res.data.per_page == 10 || res.data.per_page > 0) {
                        var card = this.card;
                        data.forEach(function (item, index) {
                            card.push(item);
                        });
                        this.card = card;
                    } else {
                        this.PageTopBankCardList--;
                    }
                }
            });
        },
        //选择充值账户触底事件
        loadt () {
            if (this.ycchongzhi) {
                return;
            }
            this.PageTopAgentAccountList++;
            var param = {
                page: this.PageTopAgentAccountList,
            };
            //
            this.$service.get(this.$api.getTopAgentAccountList, param, (res) => {
                var data = res.data.data;
                if (res.code == "200") {
                    if (res.data.per_page == 10 || res.data.per_page > 0) {
                        var card = this.zhlist;
                        data.forEach(function (item, index) {
                            card.push(item);
                        });
                        this.card = card;
                    } else {
                        this.PageTopAgentAccountList--;
                    }
                }
            }
            );
        },
        //获取银行卡
        getTopBankCardList () {
            var param = {
                page: 1,
            };
            this.$service.get(this.$api.getTopBankCardList, param, (res) => {
                var data = res.data.data;
                var card = this.card;
                if (res.code == "200") {
                    data.forEach(function (item, index) {
                        card.push(item);
                    });
                    this.card = card;
                }
            });
        },
        // 关闭
        closeDialog () {
            this.$emit("xinzeng");
            this.zhanghu = false;
        },
        // ongroupList(groupList){
        //     console.log(groupList,'groupList')
        //     var List = []
        //     groupList.forEach((v,k) => {
        //         List.agent_account_id = v.id
        //         List.account_port_id = v.account_port_id
        //     });
        //     return List
        // },
        tijiao () {
            if (!localStorage.getItem('CLASS-NAME')) { this.$message({ message: '未填写上班人员，暂无法提交内容', type: 'warning' }); return }
            var param = {
                order: {},
                order_detail: [],
            };
            param.order = this.form;
            param.order_detail = this.groupList;
            param.order.class_name = localStorage.getItem('CLASS-NAME'),
                // console.log(param, 12313);
                this.$service.post(this.$api.createTopOrder, param, (res) => {
                    if (res.code == "200") {
                        this.$emit("xinzeng");
                        this.zhanghu = false;
                    }
                });
        },
        //选择群
        blur (id) {
            // console.log(this.jieshou,22222222222)
            this.form.corporate_account_id = this.jieshou.corporate_account_id;
            this.form.company_id = this.jieshou.id;
            // console.log(this.cz)
            var param = {
                company_id: this.form.company_id,
                page: 1,
            };
            //
            this.$service.get(this.$api.getTopAgentAccountList, param, (res) => {
                if (res.code == "200") {
                    this.cz = [];
                    this.is_operating_type = "";
                    this.zhlist = res.data.data;
                }
            }
            );
        },
        //收款账户
        shoukuan (id) {
            this.card_status = this.zh.card_status; //账户类型
            console.log(this.card_status, '收款账户类型：1对私，2对公');
            this.form.bank_card_id = this.zh.id; //账户id
            this.form.card_status = this.zh.card_status; //账户类型
        },
        //充值账户
        chongzhizh (index, e) {
            console.log(this.cz, 222222222)
            this.is_operating_type = this.cz[0].is_operating_type;
            // console.log(this.is_operating_type)
            var enevt = e;
            // console.log(this.cz,111111)
            var goo_contrary_to_port_rebates = this.cz[0].advertiser.goo_contrary_to_port_rebates; //对私自运营
            var goo_private_port_rebates = this.cz[0].advertiser.goo_private_port_rebates; //对私代运营
            var sto_contrary_to_port_rebates = this.cz[0].advertiser.sto_contrary_to_port_rebates; //对公自运营
            var sto_private_port_rebates = this.cz[0].advertiser.sto_private_port_rebates; //对公代运营、
            var channel_private_port_rebates = this.cz[0].advertiser.channel_private_port_rebates; //对公自运营
            var channel_contrary_to_port_rebates = this.cz[0].advertiser.channel_contrary_to_port_rebates; //对公代运营、
            var w_channel_private_port_rebates = this.cz[0].advertiser.w_channel_private_port_rebates; //对公自运营
            var w_channel_contrary_to_port_rebates = this.cz[0].advertiser.w_channel_contrary_to_port_rebates; //对公代运营、
            var cid_channel_private_port_rebates = this.cz[0].advertiser.cid_channel_private_port_rebates; //对私cid
            var cid_channel_contrary_to_port_rebates = this.cz[0].advertiser.cid_channel_contrary_to_port_rebates;//对公cid
            var data = {
                22: goo_private_port_rebates,
                12: goo_contrary_to_port_rebates,
                21: sto_private_port_rebates,
                11: sto_contrary_to_port_rebates,
                23: channel_private_port_rebates,
                13: channel_contrary_to_port_rebates,
                24: w_channel_private_port_rebates,
                14: w_channel_contrary_to_port_rebates,
                25: cid_channel_private_port_rebates,
                15: cid_channel_contrary_to_port_rebates,
            };
            this.arr = data;
            // console.log(this.arr,'arr')
            // console.log(this.cz,'cz')
            this.groupList[index].agent_account_id = this.cz[index].id;
            this.groupList[index].account_port_id = this.cz[index].account_port_id;
            this.groupList[index].is_operating_type = this.cz[index].is_operating_type;
        },
        //添加账户
        addzhanghu () {
            var data = {
                agent_account_id: "", //
                account_port_id: "",
                user_record_price: "", //实际到账
                is_operating_type: "", //实际到账
            };
            this.groupList.push(data);
        },
        //删除账户
        dele (index) {
            var groupList = this.groupList;
            groupList.splice(index, 1);
        },

        handlePictureCardPreview (file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },

        handleRemove (file, fileList) {
            this.form.upload_img = "";
            this.form.file_id = "";
            this.form.order_price = "";
            this.kong = "";
            fileList = [];
            file = {};
            // console.log(file, this.form.upload_img);
        },
        handlePictureCardPreview (file) {
            window.open(
                "https://user.erlangcha.com/advances/Advimage?imageurl=" +
                this.form.upload_img
            );
        },
        // handleDownload(file) {
        //     console.log(file);
        // },
        //文件上传成功
        onSuccessupload (res, file, fileList) {
            this.jiazhai = true;
            this.form.order_price = "";
            this.kong = "";
            // console.log(fileList, file);
            if (fileList.length > 1) {
                fileList.splice(0, 1);
            }
            this.form.upload_img = file.response.data.file_url; //图片路径
            this.form.file_id = file.response.data.id; //图片ID
            var token = "7ce563cc4f49544991de6ed0ac743bbe";
            var prame = {
                file_id: file.response.data.id,
            };
            var header = {
                token: token,
            };
            this.$service.post(
                this.$api.getImageOcr,
                prame,
                (res) => {
                    if (res.data.price == "" || res.data.price == "0.00") {
                        this.kong = "识别失败,请手动输入";
                    } else {
                        this.form.order_price = res.data.price;
                        this.jiazhai = false;
                        this.kong = "识别成功";
                    }
                },
                header
            );
            setTimeout(() => {
                this.jiazhai = false;
                // if (
                //     this.form.order_price == "" ||
                //     this.form.order_price == "0.00"
                // ) {
                //     this.jiazhai = false;
                //     this.kong = "识别失败,请手动输入";
                // }
            }, 1000);
        },
        classObje (price) {
            // console.log(price);
            if (price == "" || price == "0.00") {
                return { color: "red" };
            }
        },
        onError (res, file, fileList) {
            // console.log(1111);
        },
    },
    mounted () {
        this.getTopCompanyList(); //获取群
        this.getTopBankCardList(); //获取银行卡
    },

    // methods: {
    // 测试用
    // onceshi(){
    // 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
    // 		console.log(123)
    // 		if(res.code == '200'){

    // 		}
    // 	})
    // },
    // }
};
</script>

<style lang="scss" scoped>
.Rechargea {
    .chuangjian {
        font-weight: bold;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .xing {
        color: red;
    }
    .kuai {
        width: 120px;
        margin-right: 25px;
        margin-left: -120px;
        text-align: left;
    }
    .kuan {
        line-height: 140px;
    }
    .chong {
        margin-left: 110px;
    }
    .chong span {
        margin-left: 30px;
        color: red;
        cursor: pointer;
    }
    .zeng .in {
        width: 500px !important;
    }
    .zengjia {
        width: 160px;
        height: 33px;
        border-radius: 5px;
        text-align: center;
        line-height: 33px;
        font-size: 16px;
        margin-left: 170px;
        cursor: pointer;
        -webkit-appearance: none;
        background-color: #fff;
        background-image: none;
        border-radius: 4px;
        border: 1px solid #dcdfe6;
        box-sizing: border-box;
        color: #606266;
        display: inline-block;
        font-size: inherit;
        height: 40px;
        line-height: 40px;
        outline: 0;
        padding: 0 15px;
        transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
}
</style>
<style lang="scss">
.Rechargea {
    .el-dialog__header {
        background: #0075f7;
        padding: 20px;
    }
    .el-dialog__title {
        color: #fff;
    }
    .el-dialog__body {
        padding: 15px 20px;
    }
    .el-form-item__content {
        display: flex;
    }
    .el-select {
        width: 500px !important;
    }
    .zeng .el-select {
        width: 250px !important;
    }
    .el-form-item {
        margin-top: 20px;
    }
    .el-dialog__footer {
        margin-top: 20px;
        text-align: center;
    }
}
</style>